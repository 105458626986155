import React from 'react'
import Layout from '../components/layout/Layout'
import Hero from '../components/modules/Hero'
import LegalPrivacy from '../components/modules/LegalPrivacy'

export default function Legal() {
    return (
        <Layout showCTA={false} isHome={false} title="Legal">
            <Hero
                title={'The Legal Stuff'}
                showContactCTA={false}
            />
            <LegalPrivacy />
        </Layout>
    )
}
