import React from 'react'

export default function LegalPrivacy() {
    return (
        <section className="px-12 py-4 pb-16 max-width">
            <h2 className="text-4xl">Cookie &amp; Privacy Policy</h2>
            <h2 className="text-2xl">Privacy Policy</h2>
            <p>
                cool.studio is owned and operated by Cool Studio Limited (Cool Studio).

                We know that you care about how your personal information is used and shared, 
                and we take your privacy seriously. Please read the following to learn more 
                about our privacy policy. By visiting Cool Studio's web site, you acknowledge that 
                you accept the practices and policies outlined in this Privacy Policy.
            </p>
            <br />
            <br />
            <h2 className="text-2xl">Personal Data We Collect</h2>
            <p>
                Cool Studio collects data to operate effectively and provide you the best experiences 
                with our products. You provide some of this data directly, such as when you create 
                an account, ask us for an online quotation, or contact us for support. We get some 
                of it by recording how you interact with our websites by, for example, using 
                technologies like cookies, and receiving error reports or usage data from software 
                running on your devices. We also obtain data from third parties.
            </p>
            <br />
            <br />
            <h2 className="text-2xl">How We Use Personal Data</h2>
            <p>
                Cool Studio uses the data we collect to operate our business and provide you the products 
                we offer, which includes using data to improve our products and personalise your 
                experiences. We also may use the data to communicate with you, for example, 
                informing you about your account, security updates and product information. And we 
                use data to help show more relevant ads or market to you, whether in our own 
                products supported by advertising or in products offered by third parties.
            </p>
            <br />
            <br />
            <h2 className="text-2xl">Reasons We Share Personal Data</h2>
            <p>
                We share your personal data with your consent or as necessary to complete any 
                transaction or provide any product you have requested or authorised. We also 
                share data with controlled affiliates and subsidiaries; with vendors working 
                on our behalf; when required by law or to respond to legal process; to protect 
                our customers; to protect lives; to maintain the security of our products; and 
                to protect the rights, staff or property of Cool Studio.
            </p>
            <br />
            <br />
            <h2 className="text-2xl">How to Access &amp; Control Your Personal Data</h2>
            <p>
                You can also make choices about how we collect and use your data. How you 
                can access or control your personal data will depend on what you want to amend.
            </p>
            <br />
            <p>
                You have a right to access any personal information that we processes about 
                you and to request information about:
            </p>
            <br />
            <ul className="list-inside list-disc">
                <li>What personal data we hold about you.</li>
                <li>The purposes of the processing.</li>
                <li>The categories of personal data concerned.</li>
                <li>The recipients to whom the personal data has/will be disclosed.</li>
                <li>How long we intend to store your personal data for.</li>
                <li>If we did not collect the data directly from you, information about the source.</li>
                <li>The right to have incomplete or inaccurate data about you corrected or completed and the process for requesting this.</li>
                <li>The right to request erasure of personal data (where applicable) or to restrict processing in accordance with data protection laws, as well as to object to any direct marketing from us and to be informed about any automated decision-making that we use.</li>
                <li>The right to lodge a complaint or seek judicial remedy and who to contact in such instance.</li>
            </ul>
            <br />
            <p>
                To obtain such information, either ask for an application form to be sent 
                to you, or write to the Data Protection Officer at our registered office. 
                In order to comply with GDPR, we have revised our SAR procedures to accommodate 
                the 30 day timeframe for providing the requested information, and for making this 
                provision free of charge.
            </p>
            <br />
            <br />
            <h2 className="text-2xl">Cookies &amp; Similar Technologies</h2>
            <p>
                Cool Studio uses cookies (small text files placed on your device) and similar 
                technologies to provide our websites and online services and to help collect 
                data. Cookies allow us, among other things, to store your preferences and 
                settings; enable you to sign-in; provide interest-based advertising; combat 
                fraud; and analyse how our websites and online services are performing.
            </p>
            <br />
            <p>
                We also use web beacons to help deliver cookies and gather usage and performance 
                data. Our websites may include web beacons and cookies from third-party service 
                providers. We may use this information to protect our staff or improve our 
                information to enhance our customer experience.
            </p>
            <br />
            <p>
                You have a variety of tools to control cookies, web beacons and similar 
                technologies, including browser controls to block and delete cookies and 
                controls from some third-party analytics service providers to opt out of 
                data collection through web beacons and similar technologies. Your 
                browser and other choices may impact your experiences with our products.
            </p>
            <br />
            <br />
            <h2 className="text-4xl">Our Cookies</h2>
            <table className="table-auto w-6/12">
                <thead>
                    <tr>
                        <td>Cookie Name</td>
                        <td>Necessity</td>
                        <td>Purpose</td>
                        <td>Expiry</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>cw_conversation</td>
                        <td>Functionality</td>
                        <td>Allow our Live Chat facility to return historic chat session data after leaving the site.</td>
                        <td>1 Year</td>
                    </tr>
                </tbody>
            </table>
        </section>
    )
}
